import React from "react";
import IconVirtualClassroom from '@images/ctype-icons/virtual_classroom.svg';
import IconClassroom from '@images/ctype-icons/classroom.svg';
import IconSelfLearningLesson from '@images/ctype-icons/self_learning_lesson.svg';
import IconInteractive from '@images/ctype-icons/interactive.svg';
import IconPdf from '@images/ctype-icons/pdf.svg';
import IconVideo from '@images/ctype-icons/video.svg';
import IconLPMini from '@images/ctype-icons/lp-icon.svg';
import IconMultiactivity from '@images/ctype-icons/multiactivity.svg';
import IconPodcast from '@images/ctype-icons/podcast.svg';
import Live from '@images/ctype-icons/live.svg';
import { Course } from '@model/CoursesClass';
import { GradientStyle, GRADIENT_VARIANT_ENUM } from '@model/GradientClass';
import { ctype } from "@utility/const";

export type CardDisplayProps = {
  course: Course;
  gradientStyle: GradientStyle;
  courseId: string;
  courseIdMaster: string;
  courseFullName: string;
  image: string;
  ctypeName: string;
  duration: string;
  iconName: string;
  badgeLabel: string;
  badgeColor: string;
  badgePriority: number;
  courseStatus: string;
  showBar: boolean;
  valueBar: number;
  isWishlist: boolean;
  isLoadingWishlist: boolean;
  toggleWishlist: () => void;
  openCourseModal: () => void;
  isNotYetAvailable: boolean;
  gradients?: Array<GRADIENT_VARIANT_ENUM>;
  isStatic: boolean;
  teacherImage: string;
  teacherName: string;
  isInModal?: boolean;
  className?: string;
  isActivity?: boolean;
  fallbackImage: string;
  isLoadingWishlistLocal?: boolean;
  previewVideoUrl?: string;
  tabIndex?: number;
  credits: number;
  summary: { __html: string };
  isParentLpBlocked: boolean;
  blockingLps: Course[];
  isLiveStream?: boolean;
  isLiveNow?: boolean;
  showLivePlay?: boolean;
  handleClickPlayButtonLivestream?: (e) => void;
  priceLabel?: string;
  useBadgeLabelLive?: boolean;
  enableWishlist?: boolean;
  noHoverEffect?: boolean;
  onHover?: () => void;
  dataElementId?: string;
  hidePricelabel:boolean;
  typeHarvard?:string;
  isComingSoon?: boolean;
  isCertificate?: boolean;
  isAssessment?: boolean;
  isUpskillProgram?: boolean;
  // Show tick if all LP's courses are completed
  showTickOnCompleteLP?: boolean;
  courseContentId?: number;
  cardRef?: (node?: Element) => void;
  clickObjectTracking?:string;
};

export const defaultCardDisplayProps = {
  courseFullName: '',
  courseStatus: '',
  showBar: false,
  valueBar: 0,
  isNotYetAvailable: false,
  gradients: [GRADIENT_VARIANT_ENUM.defaultRadial],
  isStatic: false,
  teacherName: ''
};

const ctypeIcons = {
  [ctype.VIRTUAL_CLASSROOM]: <IconVirtualClassroom />,
  [ctype.FACE_TO_FACE]: <IconClassroom />,
  [ctype.ONLINE_COURSE]: <IconSelfLearningLesson />,
  [ctype.INTERACTIVE]: <IconInteractive />,
  [ctype.PDF]: <IconPdf />,
  [ctype.VIDEO]: <IconVideo />,
  [ctype.MULTIACTIVITY]: <IconMultiactivity />,
  [ctype.PODCAST]: <IconPodcast />,
  [ctype.LEARNING_PATH]: <IconLPMini />,
  [ctype.LIVESTREAM]: <Live />
}

export const getCtypeIcon = (ctypeParam: string) => {
  if (!ctypeParam || !ctypeIcons[ctypeParam]) {
    return null;
  }

  return ctypeIcons[ctypeParam];
}
