import { LangMap, LangMapEcomm } from "@model/CoursesClass";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as actions from '@redux-actions/';
import { useSelector } from "@hooks/redux";
import ModalEvolution from "./ModalEvolution";
import TextField from "./TextField";
import Checkbox from "./Checkbox";
import ButtonV2 from "./ButtonV2";
import { useBreakpointBoolean } from "@hooks/createBreakpoint";
import { useForm } from "react-hook-form";
import { getHeaders, isFirefox, isMobile, isSafari, selectUserProfile } from "@utility/Api";
import ErrorMessage from "./ErrorMessage";
import { UserProfile } from "@model/User";
import { isMac } from "@utility/browserUtility";
import { setLearnerName } from "@redux-actions/";
import { buildClickDataTrackingObject } from "@model/TrackingClass";
import { camelCase } from "lodash";

type ModalDownloadLinkProps = {
    show: boolean;
    linkToDownload: string;
    close: () => void;
}

type DownloadLinkFormValues = {
    learnerName: string;
    //saveName: boolean;
}

const ModalDownloadLink = ({show, linkToDownload, close}: ModalDownloadLinkProps) => {
    const lang: LangMap & LangMapEcomm = useSelector((state) => state.utils.lang);
    const learnerNameFromRedux: string = useSelector((state) => state.user.learnerName);
    const userProfile: UserProfile & { fullName: string } = useSelector(selectUserProfile);
    const {isPhone, isDesktop} = useBreakpointBoolean();
    //const required = lang?.ERROR_REQUIRED;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch()

    //name for certificate should be the default value, if not present use userProfile.fullName
    const useFormValues = useForm<DownloadLinkFormValues>({
        mode: 'onSubmit',
        defaultValues: {learnerName: learnerNameFromRedux ? learnerNameFromRedux :
            !!userProfile?.learnerName ? userProfile?.learnerName : userProfile?.fullName},
    });
    const { register, errors, handleSubmit } = useFormValues;

    const handleDownloadLink = (data: DownloadLinkFormValues) => {
        actions.updateLearnerNameAndDownloadCertificate(data.learnerName, linkToDownload)
        .finally(() => {
            //dispatch(actions.getUserProfile())
            window.open(linkToDownload, '_blank');
            setIsLoading(false);
            close();
        })       
    }

    const handleDownloadIosFirefoxMac = async (data: DownloadLinkFormValues) => {
        const newWindow = window.open('about:blank', '_blank');
        await actions.updateLearnerNameAndDownloadCertificate(data.learnerName, linkToDownload);
        newWindow.location.href = linkToDownload;  
       // dispatch(actions.getUserProfile())
        setIsLoading(false);
        close();
    }

    const handleDownloadType = (data: DownloadLinkFormValues) => {
        setIsLoading(true)
        dispatch(setLearnerName(data.learnerName))
        if(isMobile.iOS() || isFirefox() || isMac()) {
            handleDownloadIosFirefoxMac(data)
        } else {
            handleDownloadLink(data)
        }
    }

    return (
        <ModalEvolution 
            show={show}
            close={close}
            modalClassName="download-link"
            title={(lang?.CERT_MODAL_TITLE).toUpperCase()}
            description={lang?.CERT_CHECK_NAME_ON_CERTIFICATE}
            hideX = {true}
            formElement={<form
            className="download-link__modal__form"
            onSubmit={handleSubmit(handleDownloadType)}
        >
                <TextField
                    type="text"
                    name="learnerName"
                    mandatory
                    ref={register({ required: lang.ERROR_REQUIRED })}
                    aria-invalid={!!errors.learnerName ? "true" : "false"}
                    isError={!!errors.learnerName ? true : false}
                    placeholder={lang?.CERT_NAME_ON_CERTIFICATE_LABEL}
                    labelFixedTop
                    //showBothPlaceholderAndLabel
                    //label={lang?.CERT_NAME_ON_CERTIFICATE_LABEL}
                    htmlFor="learnerName"
                    id="learnerName"
                />
                <ErrorMessage error={errors.learnerName} />
                {/*out of scope */}
                {/* <div className="checkbox">
                  <Checkbox
                    className=""
                    text={lang?.CERTIFICATE_CHECKBOX}
                    name="saveName"
                    ref={register}
                  />
                </div> */}
                <div className="ecommerce-alert-modal__footer">
                    <ButtonV2
                    variant="primary"
                    className="download-link--submit"
                    small={isDesktop}
                    loading={isLoading}
                    handleClick={()=>{buildClickDataTrackingObject("download_pdf",camelCase(window?.location?.pathname?.split("/").pop()));handleSubmit(handleDownloadType)}}
                    >
                    {lang.DOWNLOAD}
                    </ButtonV2>
                    <ButtonV2
                    variant={isPhone ? "text-btn-no-arrow" : "tertiary"}
                    small={isPhone || isDesktop}
                    className="download-link--cancel"
                    handleClick={close}
                    type="button"
                    // loading={isLoading}
                    disabled={isLoading}
                    >
                    {lang?.ONBOARDINGCOURSE_FORM_CONTACT_CUSTOM_BUTTON_CANCEL}
                    </ButtonV2>
                </div>
            </form>}
        />
    )

}

export default ModalDownloadLink;